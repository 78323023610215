<template>
	<div>
		<BaseLoading v-if="editInfo.isLoading" />
		<FormProductAdditionalFee
			v-else
			:fee="editInfo.data.fee"
			:sku-list="skuList"
			:is-submitting="editInfo.isUpdating"
			@onSubmit="handleSubmit"
			@onRemoveRule="handleRemoveRule"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormProductAdditionalFee from '@/components/FormProductAdditionalFee.vue';
import { priceToAPI } from '../assets/js/helpers';
import { SHIPPING_ADDITIONAL_FEE_TYPE } from '../enums/shippings';

export default {
	name: 'ShippingProductAdditionalFeeCreate',
	components: {
		FormProductAdditionalFee,
	},
	computed: {
		...mapState('additionalFees', {
			editInfo: (state) => state.additionalFees[SHIPPING_ADDITIONAL_FEE_TYPE.PRODUCT].edit,
		}),

		skuList() {
			const skus = this.editInfo.data.items || [];
			return skus.map((item) => item.sku);
		},
	},
	async mounted() {
		const { feeId } = this.$route.params;
		await this.getAdditionalFee({
			id: feeId,
			type: SHIPPING_ADDITIONAL_FEE_TYPE.PRODUCT,
		});
	},
	methods: {
		...mapActions({
			getAdditionalFee: 'additionalFees/getAdditionalFee',
			updateAdditionalFee: 'additionalFees/updateAdditionalFee',
			deleteAdditionalFee: 'additionalFees/deleteAdditionalFee',
		}),
		async handleSubmit(fee, skuList) {
			const feeId = this.$route.params.feeId;
			const params = {
				fee: priceToAPI(fee),
				type: SHIPPING_ADDITIONAL_FEE_TYPE.PRODUCT,
				item_ids: skuList,
			};

			await this.updateAdditionalFee({
				type: SHIPPING_ADDITIONAL_FEE_TYPE.PRODUCT,
				id: feeId,
				params,
			});
		},
		async handleRemoveRule() {
			const feeId = this.$route.params.feeId;
			await this.deleteAdditionalFee(feeId);
			this.$router.push({ name: 'ShippingSetting' });
		},
	},
};
</script>
